<template>
  <el-dialog append-to-body :visible.sync="visible" width="40%" @close="close">
    <div class="w-full flex flex-row mb-2">
      <div class="w-full flex flex-row justify-between text-lg font-bold inline-flex items-center">
        <strong>&nbsp;Maxifusionador</strong>
        <i class="bx bx-x cursor-pointer" style="padding: 4px; font-size: 20px" @click="close"></i>
      </div>
    </div>

    <p class="my-8">
      Selecciona el dispositivo que quieres conservar tras la fusión.
    </p>

    <!-- Contenedor visualmente separado -->
    <div class="device-list-wrapper">
      <div v-for="device in devices" :key="device.id" class="device-item">
        <el-radio v-model="selectedDevice" :label="device.id" :aria-hidden="false">
          {{ device.brand }} - {{ device.model }}
        </el-radio>
      </div>
    </div>

    <p class="my-8 text-right">
      Todos los demás dispositivos seleccionados serán fusionados en este.
    </p>

    <div class="w-full text-right mt-4">
      <el-button type="primary" :disabled="!selectedDevice" @click="fusion">
        Fusionar
      </el-button>
    </div>
  </el-dialog>
</template>
<style scoped>
.device-list-wrapper {
  background: white;
  border-radius: 8px;
  padding: 12px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.device-item {
  padding: 8px;
  border-bottom: 1px solid #eee;
}
.device-item:last-child {
  border-bottom: none;
}
</style>
<script>
export default {
  name: 'Fusion',
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    devices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDevice: null,
    };
  },
  methods: {
    close() {
      this.$emit('on:close');
    },
    fusion() {
      if (this.selectedDevice) {
        this.$emit('on:fusion', this.selectedDevice);
        this.close();
      }
    },
  }
}
</script>